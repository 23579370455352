// ----- Border styles -----
.pixel-borders {
  position: relative;
  display: inline-block;
  margin: 0 15px 15px 0;
  padding: 15px 20px;
  background-color: #fff;
  //font-family: "Press Start 2P", Arial Black, Arial Bold, Arial, sans-serif;
}

.pixel-borders-margin-0 {
  margin: 0 0 0 0;
}

// One pixel borders
.pixel-borders--1 {
  @include pixel-borders();
}

.pixel-borders--1-inset {
  @include pixel-borders($border-inset-color: #ddd);
}


// Two pixel borders
.pixel-borders--2 {
  @include pixel-borders($corner-size: 2);
}

.pixel-borders--2-inset {
  @include pixel-borders($corner-size: 2);

  &::after {
    @include pixel-borders($corner-size: 1, $border-color: #ddd);

    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
    border-image-width: 0 1 1 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
  }
}



// Examples using the pixel box mixin to colour code the elements
.pixel-box--light {
  @include pixel-box($corner-size: 1, $border-size: 4px, $background-color: #fff);
}

.pixel-box--primary {
  color: $fourth-color;
  @include pixel-box($corner-size: 1, $border-size: 6px, $background-color: $third-color, $border-inset: false);
}

.pixel-box--primary-button {
  color: $fourth-color;
  @include pixel-box($corner-size: 1, $border-size: 6px, $background-color: $third-color, $border-inset: false, $button-style: true);
}

.pixel-box--success {
  color: #fff;
  @include pixel-box($corner-size: 1, $border-size: 4px, $background-color: #92cc41);
}

.pixel-box--warning {
  @include pixel-box($corner-size: 1, $border-size: 4px, $background-color: #f7d51d);
}

.pixel-box--error {
  color: #fff;
  @include pixel-box($corner-size: 1, $border-size: 4px, $background-color: #e76e55);
}


// Custom examples to show flexibility of the mixins
.pixel-box--light-custom {
  @include pixel-box($corner-size: 1, $border-size: 4px, $background-color: #fff, $border-inset-color: #999, $border-inset-sides: 'all');
}

.pixel-box--primary-custom {
  color: #fff;
  @include pixel-box($corner-size: 1, $border-size: 4px, $background-color: #209cee, $border-inset: false);
}

.pixel-box--success-custom {
  color: #fff;
  @include pixel-box($corner-size: 1, $border-size: 4px, $background-color: #92cc41, $border-inset-sides: 'top-left');
}

.pixel-box--warning-custom {
  @include pixel-box($corner-size: 1, $border-size: 4px, $background-color: #f7d51d, $border-inset-sides: 'all');
}

.pixel-box--error-custom {
  color: #fff;
  @include pixel-box($corner-size: 1, $border-size: 4px, $background-color: #e76e55, $border-color: #000);
}
