.metamask-button {
  background-color: #bf8463;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.metamask-login {
  font-size: 10px;
}
