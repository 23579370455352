// Override default variables before the import

$first-color: #222831;
$second-color: #393e46;
$third-color: #0092ca;
$fourth-color: #eeeeff;
$fifth-color: #8dd0e0;
$sixth-color: #fa8a8a;
$seventh-color: #f2a365;
$eighth-color: #d1ffb1;

$body-bg: $first-color;
$navbar-bg: $second-color;
$navbar-text-color: $fourth-color;
$navbar-hover-color: $third-color;
$navbar-hover-bg: $second-color;
$nav-link-hover-color: $fifth-color;
$sad-color: $sixth-color;
$happy-color: $eighth-color;
