
.custom-center {
  min-width: 100%;
}

.min-h-px-250 {
  min-height: 250px;
}

.battle-button {
  z-index: 999;
  margin-left: -100px;
}


.gif_image {
  min-width: 300px;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  // z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 1109;
}

.popup-spinner {
  margin-top: 25%;
  top: 0;
  // position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1110;
}

.wwcd {
  font-size: 60px;
  animation: bounce 2s ease infinite;
  margin-left: 25px;
}

.wwcd-win {
  color: $happy-color;
}

.wwcd-lose {
  color: $sad-color;
}

@keyframes bounce {
  70% { transform:translateY(0%); }
  80% { transform:translateY(-15%); }
  90% { transform:translateY(0%); }
  95% { transform:translateY(-7%); }
  97% { transform:translateY(0%); }
  99% { transform:translateY(-3%); }
  100% { transform:translateY(0); }
}