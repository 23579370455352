.pixelart-buy-nft {
    border-radius: 5px;
    text-align: center;
    padding: 30px 80px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
    min-width: 100%;
    margin-bottom: 10px;
}
